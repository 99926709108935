<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <div class="form-row justify-content-end">
          <div class="col-md-2">
            <div class="form-group">
              <v-select
                label="title"
                required
                placeholder="Status da aula"
                :options="optionsLessonStatus"
                v-model="filters.status"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <v-select
                label="title"
                required
                placeholder="Professor"
                :options="optionsTeachers"
                @search="fetchTeachers"
                v-model="filters.teacher"
                :clearable="true"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <v-select
                label="title"
                placeholder="Disciplina"
                :options="optionsDisciplines"
                @search="fetchDisciplines"
                v-model="filters.discipline"
                :clearable="true"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Unidade"
                :options="optionsUnits"
                v-model="filters.unit"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Turno"
                :options="optionsShifts"
                v-model="filters.shift"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <input
                type="search"
                class="form-control d-inline-block"
                placeholder="Turma"
                v-model="filters.classroom"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0 mb-1">
              <flat-pickr
                class="form-control"
                placeholder="Período"
                v-model="filters.dates"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>

          <div class="col-md-1">
            <button
              @click="search"
              style="padding: 14px !important"
              type="button"
              class="btn btn-primary btn-block"
            >
              <i v-if="!btnSearch" class="bi bi-search"></i>
              <b-spinner v-else small variant="light" />
            </button>
          </div>
        </div>
      </div>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                v-model="filters.perPage"
                :options="perPageOptions"
                @input="setPerPageSelected"
                :clearable="false"
                :searchable="false"
              />
            </div>
          </div>
        </div>
      </div>

      <b-table
        :no-border-collapse="true"
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        sort-by="start_time"
        :busy="loading"
        hover
        id="printMe"
        empty-text="Nenhum registro encontrado"
      >
        <template v-slot:head(uuid)="data">
          <b-form-checkbox
            size="lg"
            v-model="isSelectedAll"
            @change="selectAll(data)"
          />
        </template>
        <template v-slot:cell(checkbox)="{ item }">
          <b-form-checkbox
            v-model="item.selected"
            @change="selectLesson(item)"
          />
        </template>

        <template #head(devolution_pilots)="data">
          <span v-html="data.label"></span>
        </template>

        <template #cell(discipline_title)="{ item }">
          {{ item.discipline_title }}
        </template>

        <template #cell(course_title)="{ item }">
          {{ item.course_title }}
        </template>

        <template #cell(start_time)="{ item }">
          {{ item.start_time }} Às {{ item.end_time }}
        </template>

        <template #cell(status_name)="{ item }">
          <b-badge class="d-block" :variant="`light-${item.status_color}`">
            {{ item.status_name }}
          </b-badge>
        </template>

        <template #cell(date)="{ item }">
          {{ item.date | datePT() }}
        </template>

        <template #cell(signature)="{ item }">
          <span v-if="item.signature">
            {{ item.signature | datePT(true) }}
          </span>
        </template>

        <template #cell(classrooms)="{ item }">
          <span v-for="(element, index) in item.classrooms" :key="index">
            {{ element.classroom_name
            }}<span v-if="index < item.classrooms.length - 1">,</span>
          </span>
        </template>

        <template #cell(modality_name)="{ item }">
          <b-badge
            class="d-block"
            :variant="`light-${
              item.modality_name === 'Live' ? 'danger' : 'info'
            }`"
          >
            {{ item.modality_name }}
          </b-badge>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              small
              class="align-middle"
              style="margin-right: 3px"
            ></b-spinner>
            <strong> carregando...</strong>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="filters.currentPage"
        :perPage="filters.perPage"
        :totalRows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
  BFormRow,
  BForm,
  BAvatar,
  BMedia,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import { mapState } from "vuex";
export default {
  components: {
    BAvatar,
    ButtonsActionsFooter,
    BMedia,
    BFormInput,
    flatPickr,
    CustomPaginateTable,
    BCard,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BFormRow,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      selectedLessons: [],
      isSelectedAll: false,
      perPageOptions: [
        {
          title: "200",
          value: 200,
        },
        {
          title: "300",
          value: 300,
        },
      ],
      optionsModalities: [],
      optionsShifts: [],
      optionsDatasheets: [],
      optionsCourseSales: [],
      optionsScholarities: [],
      optionsCourseTypes: [],
      optionsDisciplines: [],
      optionsLessonStatus: [],
      optionsUnits: [],
      optionsTeachers: [],
      optionsDevPilots: [
        { code: 1, title: "Sim" },
        { code: 0, title: "Não" },
      ],
      submitedTeacherPoint: false,
      btnSearch: false,
      filters: {
        classroom: "",
        discipline: "",
        dates: "",
        unit: "",
        teacher: "",
        status: "",
        shift: "",
        currentPage: 1,
        perPage: 100,
      },
      user: {},
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      tableColumns: [
        {
          key: "teacher_full_name",
          label: "Professor",
          sortable: false,
          class: "text-left",
          thStyle: "width: 300px",
        },
        {
          key: "classrooms",
          label: "Turmas",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "modality_name",
          label: "Modalidade",
          sortable: false,
          thStyle: "width: 100px",
          class: "text-center",
        },
        {
          key: "unit_name",
          label: "Unidade",
          sortable: false,
          class: "text-center",
        },
        {
          key: "date",
          label: "Data",
          sortable: true,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "start_time",
          label: "Horário",
          sortable: false,
          class: "text-center",
          thStyle: "width: 120px",
        },
        {
          key: "shift_name",
          label: "Turno",
          sortable: false,
          class: "text-center",
        },
        {
          key: "course_title",
          label: "Curso",
          sortable: false,
          thStyle: "width: 300px",
          class: "text-center",
        },
        {
          key: "discipline_title",
          label: "Disciplina",
          sortable: false,
          thStyle: "width: 300px",
          class: "text-center",
        },
        {
          key: "workload",
          label: "Tempo",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "status_name",
          label: "Status",
          sortable: false,
          class: "text-center",
        },
        {
          key: "signature",
          label: "Assinado",
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.filters.date = `${moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD")} até ${moment().format("YYYY-MM-DD")}`;
    this.getData();
  },
  methods: {
    async fetchLessonStatus() {
      this.optionsLessonStatus = await this.$store.dispatch(
        "Grid/forSelectLessonStatus"
      );
    },
    async fetchDisciplines(term) {
      this.optionsDisciplines = [];
      if (term.length > 2) {
        this.optionsDisciplines = await this.$store.dispatch(
          "Discipline/search",
          { term: term, ead: 0 }
        );
      }
    },
    async fetchTeachers(term) {
      this.optionsTeachers = [];
      if (term.length > 2) {
        this.optionsTeachers = await this.$store.dispatch(
          "Teacher/search",
          term
        );
      }
    },
    selectAll() {
      this.isSelectedAll = !this.isSelectedAll;
      this.users.forEach((user) => (user.selected = this.isSelectedAll));

      const notSelected = this.users.filter((user) => !user.selected);
      const selected = this.users.filter((user) => user.selected);

      // new selected users array without current page selected users
      let selectedUsersCopy = this.selectedUsers
        .slice()
        .filter((user) => !notSelected.find((e) => e.id === user.id));
      if (notSelected.length > 0) {
        this.isSelectedAll = true;
      } else {
        this.isSelectedAll = false;
      }
      this.selectedUsers = [
        ...selectedUsersCopy,
        ...selected.filter(
          (user) => !selectedUsersCopy.find((e) => e.id === user.id)
        ),
      ];

      console.log(
        "selected",
        selected,
        "this.sele",
        this.selectedUsers,
        "copy",
        selectedUsersCopy,
        "notSele",
        notSelected
      );
    },
    selectLesson(lesson) {
      this.isSelectedAll = false;
      const selected = this.dataItems.filter((lesson) => lesson.selected);
      if (selected.length === this.dataItems.length) {
        this.isSelectedAll = true;
      } else {
        this.isSelectedAll = false;
      }
      let isDouble = false;
      if (this.selectedLessons.find((v) => v.uuid === lesson.uuid))
        isDouble = true;
      if (lesson.selected) {
        if (isDouble) {
          console.log("double if lesson selected and isDouble", isDouble);
          console.log("object already exists", this.selectedLessons);
          return;
        } else {
          this.selectedLessons.push({ uuid: lesson.uuid });
          console.log("pushed", this.selectedLessons);
          return this.selectedLessons;
        }
      } else {
        const index = this.selectedLessons.indexOf(lesson);
        this.selectedLessons.splice(index, 1);
        console.log("removed, new array: ", this.selectedLessons);
      }
    },
    async fetchShifts() {
      this.optionsShifts = await this.$store.dispatch("Shift/forSelect");
    },
    async fetchUnits() {
      this.optionsUnits = await this.$store.dispatch("Unit/forSelect");
    },
    search() {
      this.btnSearch = true;
      this.getData();
    },

    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
      this.getData();
    },

    updatePage() {
      this.getData();
    },
    async getData() {
      this.loading = true;
      this.$store
        .dispatch("Grid/getAllLessonsTeacher", this.filters)
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.filters.currentPage = data.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
          this.loading = false;
        });
    },
  },
  async mounted() {
    this.fetchShifts();

    this.fetchLessonStatus();

    this.fetchUnits();

    this.optionsCourseTypes = await this.$store.dispatch(
      "Course/forSelectTypes"
    );

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:btn-print-out", (res) => {
      window.print();
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@media print {
  .main-menu,
  .paginate-area,
  .content-header-breadcrumb,
  .table tbody tr > td:first-child,
  .table thead tr > th:first-child {
    display: none;
  }
  .card {
    .m-2 {
      display: none;
    }
  }
  .table tbody tr > td {
    border-right: 1px solid #eff0f0;
  }
}
</style>
