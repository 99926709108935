var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-1", attrs: { "no-body": "" } }, [
        _c("div", { staticClass: "m-2" }, [
          _c("div", { staticClass: "form-row justify-content-end" }, [
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      placeholder: "Status da aula",
                      options: _vm.optionsLessonStatus,
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "status", $$v)
                      },
                      expression: "filters.status",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      placeholder: "Professor",
                      options: _vm.optionsTeachers,
                      clearable: true,
                    },
                    on: { search: _vm.fetchTeachers },
                    model: {
                      value: _vm.filters.teacher,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "teacher", $$v)
                      },
                      expression: "filters.teacher",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      placeholder: "Disciplina",
                      options: _vm.optionsDisciplines,
                      clearable: true,
                    },
                    on: { search: _vm.fetchDisciplines },
                    model: {
                      value: _vm.filters.discipline,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "discipline", $$v)
                      },
                      expression: "filters.discipline",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      placeholder: "Unidade",
                      options: _vm.optionsUnits,
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.unit,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "unit", $$v)
                      },
                      expression: "filters.unit",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      placeholder: "Turno",
                      options: _vm.optionsShifts,
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.shift,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "shift", $$v)
                      },
                      expression: "filters.shift",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "form-group mb-md-0 mb-1" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.classroom,
                      expression: "filters.classroom",
                    },
                  ],
                  staticClass: "form-control d-inline-block",
                  attrs: { type: "search", placeholder: "Turma" },
                  domProps: { value: _vm.filters.classroom },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "classroom", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Período",
                      config: {
                        mode: "range",
                        altInput: true,
                        dateFormat: "Y-m-d",
                        altFormat: "d/m/Y",
                        locale: "pt",
                      },
                    },
                    model: {
                      value: _vm.filters.dates,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "dates", $$v)
                      },
                      expression: "filters.dates",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-1" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-block",
                  staticStyle: { padding: "14px !important" },
                  attrs: { type: "button" },
                  on: { click: _vm.search },
                },
                [
                  !_vm.btnSearch
                    ? _c("i", { staticClass: "bi bi-search" })
                    : _c("b-spinner", {
                        attrs: { small: "", variant: "light" },
                      }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c("div", { staticClass: "form-row justify-content-between" }, [
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-md-0 mb-1" },
                  [
                    _c("v-select", {
                      attrs: {
                        label: "title",
                        options: _vm.perPageOptions,
                        clearable: false,
                        searchable: false,
                      },
                      on: { input: _vm.setPerPageSelected },
                      model: {
                        value: _vm.filters.perPage,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "perPage", $$v)
                        },
                        expression: "filters.perPage",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "no-border-collapse": true,
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              striped: "",
              "sort-by": "start_time",
              busy: _vm.loading,
              hover: "",
              id: "printMe",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "head(uuid)",
                fn: function (data) {
                  return [
                    _c("b-form-checkbox", {
                      attrs: { size: "lg" },
                      on: {
                        change: function ($event) {
                          return _vm.selectAll(data)
                        },
                      },
                      model: {
                        value: _vm.isSelectedAll,
                        callback: function ($$v) {
                          _vm.isSelectedAll = $$v
                        },
                        expression: "isSelectedAll",
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(checkbox)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("b-form-checkbox", {
                      on: {
                        change: function ($event) {
                          return _vm.selectLesson(item)
                        },
                      },
                      model: {
                        value: item.selected,
                        callback: function ($$v) {
                          _vm.$set(item, "selected", $$v)
                        },
                        expression: "item.selected",
                      },
                    }),
                  ]
                },
              },
              {
                key: "head(devolution_pilots)",
                fn: function (data) {
                  return [
                    _c("span", { domProps: { innerHTML: _vm._s(data.label) } }),
                  ]
                },
              },
              {
                key: "cell(discipline_title)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.discipline_title) + " ")]
                },
              },
              {
                key: "cell(course_title)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.course_title) + " ")]
                },
              },
              {
                key: "cell(start_time)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(item.start_time) +
                        " Às " +
                        _vm._s(item.end_time) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(status_name)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "d-block",
                        attrs: { variant: "light-" + item.status_color },
                      },
                      [_vm._v(" " + _vm._s(item.status_name) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(date)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm._f("datePT")(item.date)) + " "),
                  ]
                },
              },
              {
                key: "cell(signature)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.signature
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("datePT")(item.signature, true)) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(classrooms)",
                fn: function (ref) {
                  var item = ref.item
                  return _vm._l(item.classrooms, function (element, index) {
                    return _c("span", { key: index }, [
                      _vm._v(" " + _vm._s(element.classroom_name)),
                      index < item.classrooms.length - 1
                        ? _c("span", [_vm._v(",")])
                        : _vm._e(),
                    ])
                  })
                },
              },
              {
                key: "cell(modality_name)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "d-block",
                        attrs: {
                          variant:
                            "light-" +
                            (item.modality_name === "Live" ? "danger" : "info"),
                        },
                      },
                      [_vm._v(" " + _vm._s(item.modality_name) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-primary my-2" },
                      [
                        _c("b-spinner", {
                          staticClass: "align-middle",
                          staticStyle: { "margin-right": "3px" },
                          attrs: { small: "" },
                        }),
                        _c("strong", [_vm._v(" carregando...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              rowsTable: _vm.rowsTable,
              currentPage: _vm.filters.currentPage,
              perPage: _vm.filters.perPage,
              totalRows: _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }